export type TableOrderDesc = {
  availableItemsDesc: boolean;
  previewItemsDesc: boolean;
};

export enum FilterSelectType {
  DEPARTMENTS = 'departments',
  FILTER_BY_ASSIGNMENT_TYPE = 'filterByAssignmentType',
  FILTER_BY_PERSONNEL_TYPE = 'filterByPersonnelType',
  PERSONNEL_TYPE = 'personnelType',
  TEMPLATES = 'templates'
}

export enum ViewEditorDrawerMode {
  EDIT,
  CREATE,
}

export enum ViewEditorDrawerTabIndices {
  VIEW_PROPERTIES,
  PERSONNEL,
  ASSIGNMENTS_TALLIES,
  VIEWER_CONFIGURATION,
}

export enum ViewEditorColumnFilterFields {
  AUTO_ADD_ASSIGNMENTS = 'autoAddAssignments',
  AUTO_ADD_PERSONNEL = 'autoAddPersonnel',
  ASSIGN_STRUCTURE_ID = 'assignStructureId',
  ASSIGN_TYPE_ID = 'assignTypeId',
  DISPLAY_NAME = 'displayName',
  INACTIVE_ASSIGNMENTS = 'expired',
}

export enum PersonnelViewMembershipAccessTabs {
  ACCESS,
  MEMBERSHIP,
}

// Thank Tachyon for this stupidity
export enum BooleanYesNo {
  YES = 'yes',
  NO = 'no',
}
